import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are several ways you can contribute to and participate in Primer:`}</p>
    <ol>
      <li parentName="ol">{`Participate in discussions`}</li>
      <li parentName="ol">{`Propose a new UI pattern`}</li>
      <li parentName="ol">{`Improve the documentation`}</li>
      <li parentName="ol">{`Design or build new patterns`}</li>
      <li parentName="ol">{`Give us feedback and report bugs`}</li>
      <li parentName="ol">{`Contribute to our open source repos`}</li>
    </ol>
    <p>{`All contributions to Primer need to follow our `}<a parentName="p" {...{
        "href": "https://github.com/primer/design/blob/main/CODE_OF_CONDUCT.md"
      }}>{`code of conduct`}</a>{`.`}</p>
    <p>{`If at any time you get stuck or need help, head to #primer on Slack or start a discussion in github/primer with your question.`}</p>
    <Note mdxType="Note">
  Please note that at this time, we are not looking for external contributions from non-GitHub staff. Some of the links
  included in these documents are only available to GitHub staff.
    </Note>
    <h2>{`Participate in discussions`}</h2>
    <p>{`If you have a question, feedback or suggestion, you can start a new `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/discussions"
      }}>{`discussion`}</a>{` (GitHub staff only), or have a look at existing ones.`}</p>
    <p>{`Primer office hours are held once a week and anyone at GitHub can join, ask questions and participate in ongoing conversations. The office hours Zoom invite links are posted in Slack before the meeting starts.`}</p>
    <h2>{`Propose a new UI pattern`}</h2>
    <p>{`If you'd like to propose a new Primer pattern, big or small, let's talk! The best way to get started, especially if your proposal is in its early stages, is to start a new `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/discussions"
      }}>{`discussion`}</a>{` (GitHub staff only).`}</p>
    <p>{`If you're more certain about what you need, please open a `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/issues/new?assignees=&labels=proposal%2C+needs+triage&template=01-pattern-proposal.md&title=%5BProposal%5D+"
      }}>{`pattern request issue`}</a>{` (GitHub staff only). We will get back to you after our weekly backlog triaging session.`}</p>
    <h2>{`Improve the documentation`}</h2>
    <p>{`Documentation is a core part of Primer, and, just as design and code, we’re always trying to make it better and more useful. If you notice something missing, a typo, or have an idea of how the docs can be improved, please start a new `}<a parentName="p" {...{
        "href": "https://github.com/primer/design/discussions"
      }}>{`discussion`}</a>{`, or submit a pull request with a fix (you can do this directly via the “Edit this page on GitHub” link on the footer of docs pages).`}</p>
    <p>{`Read the Primer guidelines for `}<a parentName="p" {...{
        "href": "/guides/contribute/documentation"
      }}>{`writing documentation`}</a>{`.`}</p>
    <h2>{`Design or build new patterns`}</h2>
    <p>{`You can contribute to new patterns either by design, prototype and build proofs of concept, test in dotcom, or directly to our Primer open source repos.`}</p>
    <p>{`Please read the guidelines on `}<a parentName="p" {...{
        "href": "/guides/contribute/adding-new-components"
      }}>{`adding new components`}</a>{` and `}<a parentName="p" {...{
        "href": "/guides/contribute/design"
      }}>{`designing Primer patterns`}</a>{`.`}</p>
    <p>{`Please read the guidelines on how to `}<a parentName="p" {...{
        "href": "/guides/contribute/handling-new-patterns"
      }}>{`handle new patterns`}</a>{/* TODO: uncomment this once this page exists [adding new components](/guides/contribute/adding-new-components)*/}{` and `}<a parentName="p" {...{
        "href": "/guides/contribute/design"
      }}>{`designing Primer patterns`}</a>{`.`}</p>
    <p>{`From the `}<a parentName="p" {...{
        "href": "https://github.com/orgs/github/projects/2759"
      }}>{`Primer project board`}</a>{` (GitHub staff only), you can grab anything from `}<strong parentName="p">{`Unprioritized backlog`}</strong>{` and `}<strong parentName="p">{`Up next`}</strong>{` — just reach out to us first in Slack or the issue itself.`}</p>
    <h2>{`Give us feedback and report bugs`}</h2>
    <p>{`If you spot something that doesn’t look right in our design, code or documentation, open an issue and we'll triage it accordingly.`}</p>
    <h2>{`Contribute to our open source repos`}</h2>
    <p>{`When contributing to Primer open source repos, please follow the repo's contributing guidelines:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/css/blob/main/CONTRIBUTING.md"
        }}>{`Primer CSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/react/blob/main/contributor-docs/CONTRIBUTING.md"
        }}>{`Primer React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/view_components/blob/main/docs/contributors/README.md"
        }}>{`Primer ViewComponents`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/octicons/blob/main/CONTRIBUTING.md"
        }}>{`Octicons`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      